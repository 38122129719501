export const STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  TALKING: 2,
  FINISHED: 3,
};
const STATUS_VARIANTS = {
  INACTIVE: 'danger',
  ACTIVE: 'primary',
  TALKING: 'success',
  FINISHED: 'secondary',
  default: 'secondary',
};

export const STATUS_OPTIONS = Object.entries(STATUS).map(([key, value]) => {
  return { value, text: key, variant: STATUS_VARIANTS[key] || STATUS_VARIANTS.default };
});
