<template>
  <div class="page-content">
    <page-breadcrumb title="Native Talk Rooms" class="mb-2" />

    <data-create-popup title="Create Room"
      :schema="create_schema" :default-data="{ vocabulary: { type: 'richtext' }, useful_language: { type: 'richtext' }, }"
      :create-data-fn="createItem" @create="$refs.item_list.getList()"
    />
    <data-table-ssr id="item_list" ref="item_list"
      :columns="fields" :get-list-fn="getList"
      :delete-row-fn="deleteItem"
      :to-edit-fn="toEditPage"
    />
    <data-update-popup ref="update_data_popup" title="Update Talk Room" :schema="update_schema"
      :default-data="editing_data" :update-data-fn="updateItem"
    />
  </div>
</template>

<script>
import service from '../service'
import { STATUS_OPTIONS } from '../constant'
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Host', field: 'host', validate: { required: true } },
      { label: 'Date', field: 'date', input_type: 'date', validate: { required: true } },
      { label: 'Time From', field: 'time_from', input_type: 'time', validate: { required: true } },
      { label: 'Time To', field: 'time_to', input_type: 'time', validate: { required: true } },
      { label: 'Subject', field: 'subject' },
      { label: 'Price', field: 'price', input_type: 'number', validate: { default: 0 } },
      { label: 'Objective', field: 'objective', input_type: 'textarea'},
      { label: 'Referrer Text', field: 'referrer_text'},
      { label: 'Referrer Link', field: 'referrer_link'},
      { label: 'Questions', field: 'questions', input_type: 'textarea'},
      { label: 'Vocabulary', field: 'vocabulary', input_type: 'ui-component'},
      { label: 'Useful Language', field: 'useful_language', input_type: 'ui-component'},
    ]
  }
];

const update_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Host', field: 'host', validate: { required: true } },
      { label: 'Date', field: 'date', input_type: 'date', validate: { required: true } },
      { label: 'Time From', field: 'time_from', input_type: 'time', validate: { required: true } },
      { label: 'Time To', field: 'time_to', input_type: 'time', validate: { required: true } },
      { label: 'Subject', field: 'subject' },
      { label: 'Price', field: 'price', input_type: 'number', validate: { default: 0 } },
      { label: 'Objective', field: 'objective',input_type: 'textarea'},
      { label: 'Referrer Text', field: 'referrer_text'},
      { label: 'Referrer Link', field: 'referrer_link'},
      { label: 'Questions', field: 'questions',input_type: 'textarea'},
      { label: 'Vocabulary', field: 'vocabulary', input_type: 'ui-component' },
      { label: 'Useful Language', field: 'useful_language', input_type: 'ui-component' },
    ]
  }
];

const fields = [
  { label: 'Subject', field: 'subject' },
  { label: 'Host', field: 'host' },
  { label: 'Date', field: 'date', input_type: 'date', validate: { required: true } },
  { label: 'From', field: 'time_from', input_type: 'time' },
  { label: 'To', field: 'time_to', input_type: 'time' },
  { label: 'Price', field: 'price', input_type: 'number' },
  { label: 'Status', field: 'status', input_type: 'select', options: STATUS_OPTIONS },
];

export default {
  data(){
    return{
      create_schema,
      update_schema,
      fields,
      total: 0,
      editing_data: null,
    }
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    async getList({ limit, page, query }) {
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    toEditPage(data) {
      this.editing_data = data;
      this.$refs.update_data_popup.startEdit();
    },
    async updateItem(data) {
      service.update(data);
      this.$refs.item_list.getList();
    },
    async deleteItem({ _id }) {
      await service.delete({ id: _id });
      this.$refs.item_list.getList();
    },
  }
}
</script>
